
import { AiOutlineWindows } from 'react-icons/ai';


const IconTitle = ({ tagLine }) => {
    return (
        <div className="block-title">
            <p>
                <AiOutlineWindows fontSize='50' color="#00345e" />
                <span className="ms-3 text-capitalize">{tagLine}</span>
            </p>
        </div>
    );
};

export default IconTitle;