import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../data/GlobalContext';
import Layout from '../Layout';
import PagesHeader from '../PagesHeader';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CallToActionTwo from '../call-to-action/CallToActionTwo';
import IconTitle from '../IconTitle';
import CallToActionThree from '../call-to-action/CallToActionThree';
import GoogleMap from '../GoogleMap';

const ServicesDetalle = (props) => {
    const dbData = useContext(GlobalContext);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    let { id } = useParams();

    const subMenuServices = dbData.dbServices.map((nam, index) => {
        return (
            <li key={index}>
                <Link to={`/${nam.name.replace(" ", "-").toLowerCase()}`} onClick={goToTop}>
                    {nam.name}
                </Link>
            </li>

        )
    })


    return (
        <>
            {
                dbData.dbServices.map((item, index) => {
                    if (item.name.replace(" ", "-").toLowerCase() === id) {
                        return (
                            <Layout pageTitle={item.name}>
                                <PagesHeader
                                    title={item.name}
                                    crumbTitle={item.name}
                                    pageHeaderBg={
                                        item.description.length > 1 ?
                                            item.description[0].img
                                            :
                                            dbData.stock[1]
                                    }

                                />
                                <section className="cause-details blog-details  pt-120 pb-40">
                                    <Container className='pb-5'>
                                        <Row>
                                            <Col md={12} lg={8}>
                                                <div className="cause-details__content">
                                                    <div className="cause-card">
                                                        <div className="cause-card__inner">
                                                            <div className="cause-card__image">
                                                                <img
                                                                    src={`${item.description.length > 1 ?
                                                                        item.description[1].img
                                                                        :
                                                                        item.description[0].img
                                                                        }`}
                                                                    alt="not found"
                                                                    className='imgCardModalService'
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>{item.name}</h3>
                                                {
                                                    item.description.map((item, index) => {
                                                        return (
                                                            <p key={index}>{item.text}</p>
                                                        )
                                                    })
                                                }
                                                <div className="cause-card__bottom mt-5">
                                                    <Link className="scroll-to-target thm-btn-inverse text-capitalize" to="/contact">
                                                        free estimate
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={4}>
                                                <div className="cause-details__sidebar">
                                                    <div className="cause-details__organizer">
                                                        <h4 className="cause-details__donations-title">Our Services</h4>
                                                        {/* submenu de services */}
                                                        <ul>
                                                            {subMenuServices}
                                                        </ul>
                                                    </div>
                                                    <div className="cause-details__organizer">
                                                        <h4 className="cause-details__donations-title">Contact</h4>
                                                        {
                                                            dbData.dbPrincipal.phones.map((item, index) => {
                                                                return (
                                                                    <div>
                                                                        <a key={index} href={`tel:+1${item.phone}`}>
                                                                            <i className="azino-icon-telephone"></i>
                                                                            <span className='mx-1'> {item.phone}</span>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            dbData.dbPrincipal.emails.map((item, index) => {
                                                                return (
                                                                    <div>
                                                                        <a key={index} href={`mailto:${item.email}`}>
                                                                            <i className="azino-icon-email"></i>
                                                                            <span className='mx-1'> {item.email}</span>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <CallToActionTwo title={dbData.dbSlogan[3].slogan} bgImages={dbData.gallery[3]} />
                                    <Container>
                                        <div className="team-about__top mt-60">
                                            <Row>
                                                {
                                                    dbData.dbValues.map((value, index) => {
                                                        return (
                                                            <Col lg={4} key={index}>
                                                                <div className="block-title">
                                                                    <IconTitle
                                                                        tagLine={value.title}
                                                                    />
                                                                </div>
                                                                <p className="team-about__top-text pt-3">
                                                                    {value.description}
                                                                </p>
                                                            </Col>
                                                        );
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </Container>
                                    <CallToActionThree />
                                    <GoogleMap extraClass="contact" />
                                </section>
                            </Layout>
                        )
                    }
                })
            }

        </>
    )
}

export default ServicesDetalle;