import { useContext, useState } from "react";
import { GlobalContext } from "../data/GlobalContext";
import { Link } from "react-router-dom";

// import dbMenu from "../data/dbMenu";



const NavsLink = ({ extraClassName }) => {

    const dbData = useContext(GlobalContext);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    //sub menu
    const subitems = dbData.dbServices.map((item, index) => {
        return (
            {
                link: `/${item.name.replace(" ", "-").toLowerCase()}`,
                name: item.name,
            }
        )
    })

    // Menu
    const dbMenu = [
        {
            name: `Home`,
            link: `/`,
        },
        {
            name: `About`,
            link: `/about`,
        },
        {
            name: `Services`,
            link: `/services`,
            child: dbData.autoGntLandingFromService,
            submenu: [
                ...subitems
            ]
        },
        {
            name: `Gallery`,
            link: `/gallery`,
        },
        {
            name: `Contact`,
            link: `/contact`,
        },
    ]
    const handleDropdownStatus = (e) => {
        let clickedItem = e.currentTarget.parentNode;
        clickedItem.querySelector(".dropdown-list").classList.toggle("show");
    };



    return (
        <ul className={`main-menu__list ${extraClassName}`}>
            {
                dbMenu.map((item, index) => {
                    return (
                        item.child ?
                            <li key={index} className='dropdown'>
                                <Link to={item.link}
                                >{item.name}</Link>
                                <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                                <ul className="dropdown-list">
                                    {
                                        item.submenu.map((subItem, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link
                                                        to={subItem.link}
                                                        onClick={goToTop}
                                                    >
                                                        {subItem.name}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                            :
                            item.url ?
                                <li key={index}>
                                    <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                                </li>
                                :
                                <li key={index}>
                                    <Link to={item.link} onClick={goToTop}>{item.name}</Link>
                                </li>
                    )
                })
            }
        </ul>

    );
}

export default NavsLink;